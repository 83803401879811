html {
  scroll-behavior: smooth; /* for smooth scrolling when enganging anchors */
}

p, ul, ol {
  /* Where hyphens is not supported, this should make long words (e.g. URLs) wrap and not exceed the line. */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

main article img,
main article video {
  max-width: 100%;
  height: auto;
  display: block; /* because that is what we want it to be. Gets rid of the "invisible" margin at the bottom */
}

pre { overflow-x: auto; -webkit-overflow-scrolling: touch; }