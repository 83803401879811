.webmentions {
  margin-top: $common-margin;
  font-size: .8em;

  summary {
    margin-bottom: 1em;
  }

  &.webmentions {
    h3 {
      display: inline;
      cursor: default;
    }

    ol {
      padding: initial;
      margin: 0;
      list-style: none;
    }

    .webmention__content {
      font-size: 1.2em;
    }
  }

  .webmention__author {
    img {
      width: auto;
      vertical-align: middle;
      border-radius: 50%;
      filter: grayscale(100%);

      &:hover, &:focus { filter: none; }
    }
  }
}