@import 'assets/css/reset';
@import 'assets/css/theme';

$image-width: 100vw;
$max-image-width: 960px;
$common-margin: 5em;
$body-padding: 3vmin;
$article-width: 960px;
$common-border-radius: 3px;

::view-transition-image-pair(root) {
  isolation: auto;
}
::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
  display: block;
}

body {
  font-family: 'Iowan Old Style', 'Palatino Linotype', 'URW Palladio L', P052, serif;
  font-size: clamp(1em, calc(0.9em + 1.2vw), 1.8em);
  line-height: 1.65;
  margin: 0;
  padding: $body-padding;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  line-height: 1.3;
}

a.click-to-embed-link {
  display: block;
  position: relative;

  &::before {
    content: '►';
    display: inline-block;
    position: absolute;
    background-color: var(--background-color);
    color: var(--text-color);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    font-size: 54px;
    text-align: center;
    text-indent: 10px;
    opacity: 0.5;
    z-index: 1;
  }

  &:hover::before,
  &:focus::before {
    opacity: 0.75;
  }
}

details summary {
  cursor: pointer;
}

main {
  margin-top: $common-margin;

  & > header, & > article.articles > header {
    margin-bottom: $common-margin;
    text-align: center;

    h2 { margin: 0; }
  }

  & > nav {
    text-align: center;
    text-transform: uppercase;
    margin-top: $common-margin;

    a {
      margin-left: .3em;
      margin-right: .3em;
      text-decoration: none;
      white-space: nowrap;
    }

    a:hover, a:focus {
      text-decoration: underline;
    }
  }
}

@keyframes blink {
  50% { opacity: 0.0; }
}

.new_badge {
/*  display: none; */
  font-size: 0.5em;
  font-family: monospace;
  color: darkorange;
  vertical-align: super;
  cursor: default;
  animation: blink 1s step-start 0s 3;
}

@import 'assets/css/webmentions';

/* The main nav header */
body > header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  a { text-decoration: initial;  }
  a:hover, a:focus { text-decoration: underline; }

  // The title
  & > div {
    margin: 0 1em 0 0;
    font-weight: bold;
    text-transform: uppercase;
  }

  ol {
    list-style-type: none;
    padding: initial;
    margin: 0;

    li {
      display: inline;
      white-space: nowrap;

      a.wishlist-link {
        filter: grayscale(100%);
        text-decoration: none;

        &:hover, &:active { filter: initial }
      }

      &:not(:last-child) {
        margin-right: 0.7em;
      }

      a.selected { text-decoration: underline; }
    }
  }
}

section.vcard {
  img {
    float: right;
    margin: 0 0 1% 1%;
    border: 1px solid #AAA;
    border-radius: 50%;
    shape-outside: content-box circle();
    shape-margin: 1%;

    // Just to prevent is not resizing proportionally on tiny screens
    // only via media query, so that the UI usually reserves space for the image
    // and doesn't shift the UI once the image loaded
    @media (max-width: 160px) {
      max-width: calc(#{$article-width} - 2%);
      height: auto;
    }

    @media (max-width: 374px) {
      float: none;
      margin: 0 auto;
    }
  }
}

article {
  &.permalink {
    max-width: $article-width;
    margin: auto;
  }

  &.articles {
    & header > h1 {
      font-size: 2em;

      & > a {
        text-decoration: none;
      }
    }
  }

  header {
    & > a {
      text-decoration: none;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
    
    margin-bottom: 0.83em; /*standard for h2s*/

    h1, h2, h3 { /* h2 on permalinks, h3 on index */
      margin: 0px;
    }

    time {
      text-transform: uppercase;
      font-size: 0.8em;
    }
  }

  footer {
    font-size: 0.7em;
    text-align: center;
    text-transform: uppercase;
    font-style: italic;
    color: var(--text-color-plus2);
    margin-top: $common-margin;
  }

  &.links {
    header {
      h2, h3 {
        a { text-decoration: underline; }
      }
    }
  }

  .e-content {
    h3, h4, h5, h6 { margin-top: 2.5em; }
  }

  blockquote {
    margin-left: 1em;
    padding-left: 0.5em;
    font-size: 0.9em;
    color: var(--text-color-plus1);
    border-left: 1px solid var(--text-color-plus1);
  }

  pre {
    padding: .5em;
    font-size: .9em;
    border-radius: $common-border-radius;
  }

  @import 'assets/css/native'; /* Code highlighting theme */

  figure {
    margin-left: 0;
    margin-right: 0;

    img {
      width: 100%; /* to overwrite the img's width statement, and making the image span the full article with on large screens, too. */
    }

    figcaption {
      font-style: italic;
      font-size: 0.8em;
      letter-spacing: .03em;
      margin: .3em auto;
      padding: 0px 3%;
    }
  }

  video {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &.articles, &.read {
    &.permalink {
      .e-content {
        p:first-of-type {
          font-size: 1.15em;
        }

        figure.photo {
          margin-top: 2.5em;
          margin-bottom: 2.5em;
        }
      }
    }
  }

  /* iframes should span the whole width, unless it's from Bandcamp, where it's limited to 700px */
  iframe:not(.bandcamp) {
    width: 100%;
  }

  iframe.bandcamp {
    max-width: 100%;
  }

  div.footnotes {
    border-top-width: thin;
    border-top-style: solid;
    font-size: 0.8em;
  }
}

.blog-index {
  max-width: $article-width;
  margin: 0 auto;

  article {
    margin-bottom: 4em;
    clear: both;

    &.articles,
    &.links {
      img.teaser {
        float: right;
        width: 320px;
        margin-left: 0.5em;
        margin-bottom: 0.5em;
      }
    }

    &.videos img {
      width: 100%;
    }

    &.notes {
      .image-teaser-gallery {
        display: flex;
        gap: 0.5em;
      }
    }

    &.links {
      h2 {
        &::after {
          content: ' 🔗';
        }
      }
    }
  }
}

.home-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 5vw;

  & > section:first-child {
    flex-basis: 960px;
  }

  &>section:last-child {
    flex-basis: 220px;
    flex-grow: 1;
  }
}

section#events {
  font-size: max(0.6em, 16px);
}

section#events .p-summary {
  color: var(--text-color-plus3);
}

section#events h3 {
  margin-bottom: 0;
}

section#events p {
  margin: 0;
}

section#events time {
  font-weight: bold;
}

/* Non-MEDIA Posts: Articles, Notes, Links and Videos */
.text-index {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(unquote('min(300px, 100%)'), 1fr));
  grid-gap: 0.6em;

  &.masonry {
    grid-template-rows: masonry;
  }
  
  /* For the /blog overview */
  &.overview {
    grid-template-columns: repeat(auto-fill,minmax(260px, 1fr));
  }

  article {
    position: relative;
    font-size: 1.2rem;
    border: 1px solid var(--text-color-plus4);
    border-radius: $common-border-radius;

    header {
      padding-top: 3%;
      padding-left: 3%;
      padding-right: 3%;
    }

    p {
      padding-left: 3%;
      padding-right: 3%;
    }

    .e-content img {
      width: 100%;
      height: auto;
    }

    p.p-summary { color: var(--text-color-plus2); }

    &.articles img.teaser,
    &.links img.teaser,
    &.videos img.teaser {
      position: relative;
      width: 100%;
      border-top-left-radius: $common-border-radius;
      border-top-right-radius: $common-border-radius;
      height: 200px;
      object-fit: cover;
    }

    /* making whole index <article> clickable */
    &.articles {
      header > h2 > a::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    &.notes {
      .image-teaser-gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 0.1em;

        & > * {
          flex-basis: 49%;
          flex-grow: 1;
        }
      }
    }
  }
}

.text-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;

  & > section {
    flex-basis: 260px;
    flex-grow: 1;
  }
}
/* MEDIA Posts: Watched Movies, Read Books, and Music */
.media-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;

  & > section {
    flex-basis: 360px;
    flex-grow: 1;
  }
}

article.media {
  header p {
    margin: 0;
    font-style: italic;
    color: var(--text-color-plus1)
  }

  header > span {
    float: right;
  }

  img {
    width: 100%;
    height: auto;
  }
}

article.media.permalink {
  img.book-cover, img.movie-poster {
    float: right;
    width: 200px;
    height: 300px;
    margin: 0 0 0.5em 0.5em;
    border-radius: $common-border-radius;
  }
}

article.movies details summary {
  font-style: italic;
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(220px,1fr));
  grid-gap: 0.2em;

  article {
    font-size: 1rem;
    position: relative;

    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    img {
      border-radius: $common-border-radius;
      height: 100%;
      object-fit: cover;
    }

    header {
      margin-bottom: 0px; /*undoing a setting from further up */
      position: absolute;
      bottom: 0;
      width: 94%;
      padding: 3%;
      background-color: rgba(17,17,17,0.6);
      border-bottom-left-radius: $common-border-radius;
      border-bottom-right-radius: $common-border-radius;

      p {
        color: var(--text-color-artwork-overlay);
      }
    }
  }

  & > * {
    a, a:visited {
      color: var(--text-color-artwork-overlay);
    }
  }
}
