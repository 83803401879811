$background-color: #FBFBFB;
$header-background-color: white;
$text-color: #222;
$text-color-plus1: #434343;
$text-color-plus2: #646464;
$text-color-plus3: #747474;
$text-color-plus4: #bdbdbd;

:root {
  color-scheme: light dark;
  --background-color: #{$background-color};
  --header-background-color: #{$header-background-color};
  --text-color: #{$text-color};
  --text-color-artwork-overlay: #{invert($text-color)};
  --text-color-plus1: #{$text-color-plus1};
  --text-color-plus2: #{$text-color-plus2};
  --text-color-plus3: #{$text-color-plus3};
  --text-color-plus4: #{$text-color-plus4};

  @media (prefers-color-scheme: dark) {
    --background-color: #222 !important;
    --header-background-color: #111;
    --text-color: #{invert($text-color)};
    --text-color-artwork-overlay: #{invert($text-color)};
    --text-color-plus1: #{invert($text-color-plus1)};
    --text-color-plus2: #{invert($text-color-plus2)};
    --text-color-plus3: #{invert($text-color-plus3)};
    --text-color-plus4: #666;
  }
}

body {
  background-color: var(--background-color);

  color: var(--text-color);
  fill: var(--text-color); /* for SVGs */
}

a {
  color: var(--text-color);
  fill: var(--text-color);
}

a:visited {
  color: var(--text-color-plus1);
  fill: var(--text-color-plus1);
}

a:active {
  color: var(--text-color-plus2);
  fill: var(--text-color-plus2);
}

main {
  article img,
  article video {
    background-color: var(--text-color-plus4);
  }
}

body > header {
  a:visited {
    color: var(--text-color);
  }
}

article {
  header {
    time {
      color: var(--text-color-plus3);
    }
  }

  figure {
    figcaption {
      color: var(--text-color-plus2);
    }
  }

  div.footnotes {
    border-top-color: var(--text-color-plus4);
  }
}